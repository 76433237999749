import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import VideoMP4 from '../../../components/Video/VideoMP4';
import Layout from '../../../Layout';
import { Button } from '../../../Molecules/Button/Button';
import TitleHistory from '../../../Molecules/TitleHistory/TitleHistory';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import { getImage, getVideo, getBreadCrumb } from '../../../Utils/Utils';

import './styles.scss';

const ColasHistory = ({ data }) => {
  let classNames = require('classnames');
  const intl = useIntl();

  const historyData = data?.historyData?.edges[0]?.node;
  const imagesArray = data?.allImages?.edges;
  const videosArray = data.allVideos.edges;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, historyData.path?.alias);
  const [innerNav, setInnerNav] = useState([]);

  const stringToHTML = (text) => {
    return { __html: text };
  };

  const getNavList = () => {
    historyData.relationships?.field_blocs?.forEach((block) => {
      block.__typename === 'block_content__block_histoire' &&
        navList.push({
          title: block.field_title?.processed,
          section: block.field_decade,
        });
    });

    return navList;
  };

  useEffect(() => {
    let navList = [];
    historyData.relationships?.field_blocs?.forEach((block) => {
      block.__typename === 'block_content__block_histoire' &&
        navList.push({
          title: block.field_title?.processed,
          section: block.field_decade,
        });
    });
    setInnerNav(navList);
  }, []);

  return (
    //
    <Layout imagesArray={imagesArray}>
      <Seo
        title={historyData.title}
        description="Description de la page Colas History"
      />
      <div className={classNames('page_template', 'colas_history_page')}>
        {innerNav.length > 0 && <ScrollPageNav data={innerNav} />}
        <div className="wrapper_page">
          <Breadcrumb
            page_banner={false}
            data={{
              //grandParentPage: { title: 'Acceuile', url: '/' },
              gparent: { title: breadCrumb?.grandParent?.name, url: breadCrumb?.grandParent?.link },
              parentPage: { title: breadCrumb?.parent?.name, url: breadCrumb?.parent?.link },
              currentPage: { title: breadCrumb?.current?.name, url: breadCrumb?.current?.link },
              locale: historyData.langcode
            }}
          />
          <TitlePage color="color_dark_bleu" title={historyData.title} />
        </div>

        {historyData.relationships?.field_blocs?.map((block, i) => {
          switch (block.__typename) {
            case 'block_content__block_video':
              return (
                <div className="wrapper_page" key={i}>
                  <VideoMP4
                    cover={
                      getImage(
                        imagesArray,
                        block?.relationships?.field_image?.drupal_internal__mid
                      )?.publicURL
                    }
                    videoLink={
                      getVideo(
                        videosArray,
                        block?.relationships?.field_mp4?.drupal_internal__mid
                      )?.publicURL
                    }
                  />
                </div>
              );

            case 'block_content__block_histoire':
              return (
                <section
                  className={classNames('section_content', block.field_decade)}
                  key={i}
                >
                  <div className="only_mobile_tablet wrapper_page">
                    <TitleHistory
                      label={intl.formatMessage({ id: 'history.decade.label' })}
                      title={block.field_title?.processed}
                      type="line1"
                    />
                    <div className="image_year">
                      <img
                        src={
                          getImage(
                            imagesArray,
                            block.relationships?.field_images_multiple[0]
                              ?.drupal_internal__mid
                          )?.publicURL
                        }
                        alt=""
                      />
                    </div>
                    <div className="text_section">
                      <div
                        className="list_container"
                        dangerouslySetInnerHTML={stringToHTML(
                          block.body?.processed
                        )}
                      ></div>
                    </div>
                    <div className="button_discover">
                      <Button
                        primary={true}
                        label={block.field_lien?.title}
                        link={block.field_lien?.url}
                      />
                    </div>
                  </div>
                  <div className="only_desktop wrapper_page">
                    <div className="middle_desktop">
                      <div className="text_container">
                        <div className="container_title">
                          <TitleHistory
                            label={intl.formatMessage({
                              id: 'history.decade.label',
                            })}
                            title={block.field_title?.processed}
                            type="line1"
                          />
                        </div>
                        <div className="text_section">
                          <div
                            className="list_container"
                            dangerouslySetInnerHTML={stringToHTML(
                              block.body?.processed
                            )}
                          ></div>

                          <div className="button_discover">
                            <Button
                              primary={true}
                              label={block.field_lien?.title}
                              link={block.field_lien?.url}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="image_year">
                        {block.relationships?.field_images_multiple?.map((image, i) => (
                          <>
                            {i === 0 ? (
                              <div className="visuel">
                                {image?.drupal_internal__mid &&
                                  <Img
                                    style={{ height: '100%', width: '100%' }}
                                    imgStyle={{ objectFit: 'cover' }}
                                    alt=""
                                    fluid={{ ...getImage(imagesArray, image.drupal_internal__mid).childImageSharp.fluid, aspectRatio: 0.700808625 }}
                                  />
                                }
                              </div>
                            ) : (
                              <div className="visuel">
                                {image?.drupal_internal__mid &&
                                  <Img
                                    style={{ height: '100%', width: '100%' }}
                                    imgStyle={{ objectFit: 'cover' }}
                                    alt=""
                                    fluid={{ ...getImage(imagesArray, image.drupal_internal__mid).childImageSharp.fluid, aspectRatio: 1.42857143 }}
                                  />
                                }
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    </div>
                  </div>
                </section>
              );
          }
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query historyTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
    $videoID: [Int!]
  ) {
    historyData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_histoire {
                  field_decade
                  field_title {
                    processed
                  }
                  relationships {
                    field_images_multiple {
                      drupal_internal__mid
                    }
                  }
                  body {
                    processed
                  }
                  field_lien {
                    title
                    url
                  }
                }
                ... on block_content__block_video {
                  relationships {
                    field_mp4 {
                      drupal_internal__mid
                    }
                    field_image {
                      drupal_internal__mid
                    }
                  }
                }
              }
            }
          }
          path {
            alias
            langcode
          }
          title
          langcode
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              localFile {
                publicURL
                childImageSharp {
                  fixed(width: 456, height: 456) {
                    ...GatsbyImageSharpFixed
                  }
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                  mobile: fixed(width: 456, height: 456) {
                    ...GatsbyImageSharpFixed
                  }
                  desktop: fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          langcode
        }
      }
    }

    allVideos: allMediaVideo(
      filter: { langcode: { eq: "fr" }, drupal_internal__mid: { in: $videoID } }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_video_file {
              localFile {
                publicURL
              }
            }
          }
          langcode
        }
      }
    }

    allMenu :   allMenuLinkContentMenuLinkContent(
      filter: {langcode: {eq: $locale}}
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default ColasHistory;
